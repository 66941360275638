.main_container {
    height: 450px;
    display: flex;
}

.main_container .large_image {
    height: 450px;
    width: 75%;
}

.main_container .large_image img {
    width: 100%;
    height: 100%;
}

.main_container .small_image {
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
}

.main_container .small_image img {
    width: 100%;
    height: 33%;
}

@media (max-width:480px) {
    .main_container {
        height: 250px !important;
        display: flex;
    }

    .main_container .large_image {
        height: 250px;
        width: 75%;
    }

}