.filter_container::-webkit-scrollbar {
    display: none;
  }
.filter_container{
    background-color: #fff;
    box-shadow: 0px 1px 25px rgba(0, 0, 0, 0.25);
    border-radius: 46px;
    display: flex;
    align-items: center;
    overflow-x: scroll;
    overflow-y:hidden;
}
.filter_container ul{
    list-style: none;
    display: flex;
    margin:0;
    padding:0;
}
.filter_container ul li{
    display: flex;
    align-items: center;
    justify-content: center;
    border-right:1px solid #ccc;
    width:150px;
    height: 60px;
    font-size: 14px;
}
.filter_container ul li:hover{
    cursor: pointer;
}
.filter_container ul li:last-child {
    border:none;
  }
.filter_container .icon{
    font-weight: bold;
}