.search {
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 5px solid #DEDEDE;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    border-radius: 30px;
}

.search input {
    border: none;
    box-shadow: 0;
    outline: none;
    width: 100%;
}

.search i:hover {
    cursor: pointer;
}

.line {
    height: 45px;
    width: 5px;
    background-color: #DEDEDE;
    /* margin-right: -2px; */
}

.banner__fadeBottom {
    background-image: linear-gradient(180deg, transparent, rgba(37, 37, 37, .61), #111);
    height: 7.4rem;
}

.search__screen {
    background-image: url('https://d3nn873nee648n.cloudfront.net/900x600/11596/20-SM346802.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60vh;
    background-color: red;
}

@media (max-width:480px) {
    .search__screen {
        height: 45vh;
    }

    .banner__fadeBottom {
        margin-top: 20px;
        height: 3rem;
    }
}