.service_container::-webkit-scrollbar {
    display: none;
}

.service_container {
    background: #fff;
    /* margin:0 50px; */
    border-radius: 20px;
    overflow: hidden;
    display: flex;
    justify-content: space-around;
    overflow-x: scroll;
    overflow-y: hidden;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
}

.service_container .active {
    -webkit-filter: grayscale(100%)
}

.service_container a {
    color: #000;
    text-decoration: none;
}

.service {
    height: 60px;
    width: 120px;
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
}

.service:hover {
    border-bottom: 3px solid #18A0FB;
    cursor: pointer;
}

.service_title {
    font-size: 8px;
    text-align: center;
}

@media (max-width:480px) {
    .service_container {
        display: grid;
        grid-template-columns: auto auto auto;
    }

    .service {
        height: 70px;
        width: 90px;
        padding: 0 10px;
        margin-bottom: 10px;
        align-items: center;
        justify-content: center;
        transition: 0.4s;
    }
}