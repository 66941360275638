.filterContainer {
    height: 80px;
    width: 95%;
    border: 1px solid #ccc;
    border-radius: 20px;
    display: flex;
    margin: 0 auto;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.filterContainer .form-switch {
    font-size: 15px;
}

.filterBox {
    height: 80px;
    width: 33.3%;
    border-radius: 20px 0 0 20px;
    display: flex;
    flex-direction: column;
}

.filterBox:not(:last-child) {
    border-right: 1px solid #ccc;
}

.filterCustomBox {
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    font-size: 24px;
}

.upper {
    height: 40px;
    width: 100%;
    display: flex;
    font-size: 20px;
    justify-content: space-around;
    align-items: center;
}

.filter_title {
    font-size: 18px;
    font-weight: 500;
}

.filter_sub_title {
    font-size: 22px;
    font-weight: 700;
}

.right_side {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.lower {
    height: 40px;
    width: 100%;
    display: flex;
    font-size: 20px;
    margin-left: 40px;
    /* justify-content: center; */
    align-items: center;
}

.left {
    height: 80px;
    display: flex;
    flex-direction: column;
}

@media (max-width:480px) {

    .filterContainer,
    .filterBox {
        height: 60px;
    }

    .filterContainer {
        width: 100%;
    }

    .filter_title {
        font-size: 8px;
    }

    .filter_sub_title {
        font-size: 12px;
    }

    .calendar {
        height: 20px;
    }

    .upper {
        height: 30px;
    }

    .lower {
        margin-left: 10px;
        height: 30px;
    }

}