.my__header_container {
    background-color: #fff;
}

.my__header .nav-item {
    padding: 0 10px;
    margin: 0;
}

.my__header .nav-item .nav-link {
    /* background-color: aqua; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #000;
    font-size: 12px;
}

.my_header_icon {
    height: 20px !important;
    font-size: 20px !important;
    margin-bottom: 10px;
}