.card_container{
    box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    margin:10px 0;
}

.card_container .rating{
    font-size: 14px;
}
.card_container .img-style{
    width: 100%;
    max-height: 259px;
    border-radius: 10px 10px 0px 0px;
}
.card_container .heart-color{
    color: deeppink;
}
.three_section{
    margin-top: 2px;
    display: flex;
    justify-content: space-between;
}
.three-style{
    height: 100px;
    width:33%;
    border-radius: none!important;
}
.title-1{
 text-decoration:none;
 color:black}