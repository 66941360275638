.wedding {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 400px;

}

.package_heading {
    font-size: 40px;
    color: #fcf9f0;
    font-weight: 600;
}

.explore {
    padding: 15px;
    width: 300px;
    border-radius: 10px;
    border: none;
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    background-color: rgba(0, 0, 0, 0.3);
    transition: 0.4s;
}

.explore:hover {
    background-color: rgba(0, 0, 0, 0.5);
    transition: 0.4s;
}

@media (max-width:320px) {
    .wedding {
        height: 200px;
    }

    .package_heading {
        font-size: 22px;
    }

    .explore {
        width: 200px;
    }
}