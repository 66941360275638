.sub_category {
    margin-top: -50px;
    background-color: #c6e7fd;
    padding-top: 50px;
    border-radius: 20px 20px 0 0;
    overflow: hidden;
    margin-bottom: 50px;
}

.sub_category .item {
    border-bottom: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
}

.sub_category .item:hover {
    background-color: #41B0FA;
    cursor: pointer;
    transition: 0.4s;
}