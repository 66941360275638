.text-section{
    height: 50px;
    width: 100%;
    color:#fff;
    text-overflow: ellipsis;
    overflow: hidden;
}
.blog_anchor{
    text-decoration: none;
}
.blog_container h5{
    color:#000;
}
.blog_container .time
{
    color: #000;
}