.ratingstar h4{
    text-align: center;
  justify-content: center;
  /* margin: 10px; */
}
.ratingstar h3{
  text-align: center;
  justify-content: center;
  border: 2px solid;
  padding: 30px;
  width: 100%px;
  border-color: #3fb0ff;
  border-radius: 10px;
}
.starfill{
    font-size: 20px;
    margin: 2px;
    color: #ffbb10;
}
.starborder{
    text-align: center;
    justify-content: center;
}
.progress{
    height: 12px;
    margin-bottom: 40px;
}
.singlestar{
    font-size: 20px;
    margin: 2px;
    color: #ffbb10;
}
.ratingdistribution h4{
    text-align: center;
    justify-content: center;
    margin: 10px;
    margin-bottom: 30px;
}
.writerating {
    flex-direction: column;
    border: 2px solid #3fb0ff;
    border-radius: 10px;
    
}
.writerating textarea{
    width: 100%;
    border: none;
    box-shadow: none;
    outline: none;
    padding: 10px;
    resize: none;
}
.writerating button{
margin: 10px;
border-radius: 20px;
padding: 5px 15px;
border: none;
background-color: #3fb0ff;
color: white;
font-weight: 500;
float: right;
}
.ratings img{
 height: 50px;
 width:50px;
 object-fit: contain;
 border-radius: 50%;
}
.ratings .name{
    margin-left: 10px;
    align-items: center;
    justify-content: center;
}
.ratings .date{
    margin-left: 10px;
    color: rgb(118, 112, 112);
    font-weight: 400;
}
.main_rating{
    display: flex;
    align-items: stretch;
    padding-left: 20px;
}
.main_rating .star{
    margin-right: 3px;
    color:#ffbb10;
    font-size: 22px;
}
.main_rating h5{
    margin-left: 5px;
}
.para{
    text-align: justify;
}