.footer {
    background-color: #ffffff;
}

.footer_list ul li {
    list-style: none;
}

.footer_list ul li a {
    text-decoration: none;
    color: #000;
    font-weight: 500;
}

.social_link {
    display: flex;
    margin-bottom: 10px;
}

.social_link .link_item {
    height: 30px;
    width: 30px;
    background-color: #000;
    border: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 0 5px;
    color: #fff;
}

.consult_link p {
    text-align: center;
}

.consult_link a {
    text-decoration: none;
    font-size: 12px;
    color: #0071BC;
}

.phone_section a {
    text-decoration: none;
    color: #000;
}