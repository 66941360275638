.book_item {
    height: 120px;
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.book_image {
    height: 80px;
    width: 80px;
    text-align: center;
    border-radius: 50%;
}

.book_image img {
    height: 80px;
    width: 80px;
    border-radius: 50%;
    text-align: center;
}

.book_title {
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 600;
}

.my__href {
    text-decoration: none;
    color: #000;
    font-weight: 600;

}

.slick-prev:before,
.slick-next:before {
    display: none;
}

@media only screen and (max-width: 420px) {
    .book_image img {
        height: 60px;
        width: 60px;
    }

    .my__href {
        font-size: 10px;
    }

    .book_item {
        height: 80px;
    }
}