.title {
    font-weight: 600;
    font-size: 18px;
}

.submit_btn {
    background-color: #4995c8;
    color: white;
    padding: 15px;
    width: 200px;
    border: 0px;
    border-radius: 15px;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 50px;
    margin-top: 30px;
}