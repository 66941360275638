@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap');

.link_style {
    text-decoration: none;
    color: #000;
}

.chechlist_heading {

    font-family: 'Dancing Script', cursive;
    font-size: 46px;
}

.checklist {
    background-image: url('./checklist.jpg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.underline_img {}