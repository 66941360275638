.header {
  justify-content: space-between;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 380px;
  object-fit: cover;
  position: relative;
}

.show_icon {
  font-size: 18px;
  cursor: pointer;
}

.show_icon:hover {
  color: deeppink;
}

.show_icon:last-child {
  border-right: none;
}

.head_section {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.heading {
  font-weight: bold;
}

.cln_btn {
  background-color: #40b0fa;
  padding: 10px;
  border-radius: 10px;
  border: none;
  color: white;
  font-weight: bold;
}

.cln_icon {
  font-size: 20px;
}

.cln_icon span {
  color: #40b0fa;
  background-color: red;
}

.text {
  color: #ccc;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 0px;
  margin-top: 0px;
}

.text span {
  color: #40b0fa;
  font-weight: 400;
}

.star_rate {
  display: flex;
  align-items: baseline;
  margin-bottom: 5px;
}

.star_rate .star {
  color: #ffba0f;
  font-size: 24px;
}

.img_btn {
  position: absolute;
  position: fixed;
  bottom: 50px;
  right: 50px;
  background-color: #555;
  color: white;
  font-size: 16px;
  padding: 12px 24px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 50px;
  width: 100px;
  background-color: red;
  z-index: 999;
}

.center_div {
  background-color: #f3f3f3;
  box-shadow: 5px 5px 25px rgba(0, 0, 0, 0);
  border-radius: 5px;
}

.center_div ul {
  list-style-type: none;
}

.center_div ul li {
  padding: 5px;
  font-size: 16px;
  font-weight: 500;
}

.btn_style {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center;
}

.btn_style button {
  padding: 15px;
  color: white;
  font-weight: 600;
  background: linear-gradient(270deg, #045A94 0%, rgba(0, 113, 188, 0.86) 100%) !important;
  border: none;
  border-radius: 5px;
  width: 100%;
  vertical-align: center;
}

.btn_style button:not(:last-child) {
  background: red;
}

.last_para {
  float: right;
  margin-right: 10px;
}

.fucn_del {
  border: none;
  background-color: #fff;
  border-bottom: 2px solid;
}

@media (max-width: 480) {
  .center_div {
    height: 90vh;
  }

  .highlight p {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .pricing__section p {
    font-size: 8px;
    background-color: orange;
  }

  .pricing__section p {
    font-size: 8px;
    background-color: orange;
  }
}




.form_preview {
  background-color: #F3FCFB;
  box-shadow: 5px 5px 25px rgba(0, 0, 0, 0);
  border-radius: 5px;
}


.form_preview form input,
[type="date"],
select {
  border: none;
  outline: none;
  box-shadow: 0;
  border-radius: 0;
  /* border-radius: 50px; */
  background-color: #fff;
  margin-bottom: 10px;
  align-items: center;
  justify-content: center;
  /* box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); */
}

.form_preview select {
  background-color: #fff;
}

.form_preview form input:focus,
.form_preview form select {
  border: none;
  background-color: #fff;
}




.sbt_btn {
  background-color: #40b0fa !important;
  color: #fff !important;
}

.ttk_table p {
  margin: 0;
  padding: 0;
}

.ttk_table th {
  color: #40b0fa;
}